/// <reference path="../vendor/jquery-1.11.0.intellisense.js" />
/// <reference path="../vendor/jquery-1.11.0.js" />
(function ($)
{
	/// <param name="$" type="jQuery"></param>

	$('form#loginform').submit(function (e)
	{
		/// <param name="e" type="Event"></param>
		e.preventDefault();

		var self = $(this),
			username = self.find('input[name=Username]').val(),
			password = self.find('input[name=Password]').val(),
			action = self.attr("action"),
			method = self.attr("method"),
			returnurl = self.data("returnurl"),
			validationSummary = self.find('.validation-summary'),
			loginFail = validationSummary.find('#loginFail');

		if (self.valid())
		{
			$.ajax(action, {
				type: method,
				dataType: 'json',
				data: { Username: username, Password: password },
				success: function (data)
				{
					if (data.success === true)
					{
						if (typeof (returnurl) === 'string' && returnurl.length > 0)
						{
							window.location.href = returnurl;
						} else
						{
							window.location.reload();
						}
					} else
					{
						validationSummary.show();
						loginFail.show();
					}
				}

			});
		}
	});

	$('a#logout').click(function (e)
	{
		e.preventDefault();
		var self = $(this),
			url = self.attr('href');

		$.ajax(url, {
			type: 'get',
			dataType: 'json',
			success: function (data)
			{
				if (data.success === true)
				{
					if (typeof (returnurl) === 'string' && returnurl.length > 0)
					{
						window.location.href = returnurl;
					} else
					{
						window.location.reload();
					}
				}
			}

		});
	});

})(jQuery);